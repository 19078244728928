import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IncomingCallInfo,
  NetworkInformation,
  NetworkUserInformation,
  Participant,
  Translations,
  UserInformation,
} from "../lounge.types";

type LoungeSliceState = {
  loungeParticipants: Participant[];
  isWhereByOpen: boolean;
  isCallWaiting: boolean;
  participantName: string;
  videoCallInfo: IncomingCallInfo;
  userInformation: UserInformation;
  whereByMeetingUrl: string;
  loginUserHiveId: string;
  loungeSceneId: string;
  videoConference: [];
  isCallRejected: boolean;
  isCallRegretted: boolean;
  profilePicture: string;
  networkUserInformation: NetworkUserInformation;
  translations: Translations;
  loginToken: string;
  networkInformation: NetworkInformation;
};

const initialState: LoungeSliceState = {
  loungeParticipants: [],
  isWhereByOpen: false,
  isCallWaiting: false,
  participantName: "",
  videoCallInfo: {},
  userInformation: { loginUserId: "", loginUserName: "" },
  whereByMeetingUrl: "",
  loginUserHiveId: "",
  loungeSceneId: "",
  videoConference: [],
  isCallRejected: false,
  isCallRegretted: false,
  translations: {},
  profilePicture: "",
  networkUserInformation: {},
  loginToken: "",
  networkInformation: {
    type: null,
    meetings: [],
    participants: [],
    id: "",
    imageIndex: 0,
  },
};

export const loungeSlice = createSlice({
  name: "loungeData",
  initialState,
  reducers: {
    addLoungeParticipant: (
      state,
      action: PayloadAction<Participant | Participant[]>
    ) => {
      //first time you enter the lounge, it is possible to have an array of people in the lounge already.
      // This logic takes into account that there can be more than one participant added at a time.
      const participants = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];

      participants.forEach((participant) => {
        if (
          !state.loungeParticipants.find((p) => p.userId === participant.userId)
        ) {
          state.loungeParticipants.push(participant);
        }
      });
    },

    removeLoungeParticipant: (
      state,
      action: PayloadAction<Participant["userId"]>
    ) => {
      state.loungeParticipants = state.loungeParticipants.filter(
        (participant) => participant.userId !== action.payload
      );
    },
    removeAllLoungeParticipants: (state) => {
      state.loungeParticipants = [];
    },
    setWhereByOpenStatus: (state, action: PayloadAction<boolean>) => {
      state.isWhereByOpen = action.payload;
    },
    getIsCallWaitingStatus: (state, action: PayloadAction<boolean>) => {
      state.isCallWaiting = action.payload;
    },
    getParticipantName: (state, action: PayloadAction<string>) => {
      state.participantName = action.payload;
    },
    incomingVideoCallInfo: (state, action: PayloadAction<{}>) => {
      return {
        ...state,
        videoCallInfo: action.payload,
      };
    },
    getUserInformation: (
      state,
      action: PayloadAction<{
        loginUserId: string;
        loginUserName: string;
      }>
    ) => {
      return {
        ...state,
        userInformation: action.payload,
      };
    },
    setWhereByMeetingUrl: (state, action: PayloadAction<string>) => {
      state.whereByMeetingUrl = action.payload;
    },
    getUserHiveId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loginUserHiveId: action.payload,
      };
    },
    getLoungeSceneId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loungeSceneId: action.payload,
      };
    },
    getVideoConference: (state, action: PayloadAction<[]>) => {
      return {
        ...state,
        videoConference: action.payload,
      };
    },
    getIsCallRejected: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isCallRejected: action.payload,
      };
    },
    getIsCallRegretted: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isCallRegretted: action.payload,
      };
    },
    getChatTranslations: (state, action: PayloadAction<{}>) => {
      return {
        ...state,
        translations: action.payload,
      };
    },
    getProfilePicture: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        profilePicture: action.payload,
      };
    },
    getLoginToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loginToken: action.payload,
      };
    },
    getNetworkUserInformation: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        networkUserInformation: action.payload,
      };
    },
    getNetworkInformation: (
      state,
      action: PayloadAction<NetworkInformation>
    ) => {
      const { type, meetings, participants, id, imageIndex } = action.payload;

      if (meetings) {
        return {
          ...state,
          networkInformation: {
            type: "room",
            ...state.networkInformation,
            meetings,
            id,
          },
        };
      } else if (participants) {
        return {
          ...state,
          networkInformation: {
            ...state.networkInformation,
            participants,
            id,
          },
        };
      } else if (type) {
        return {
          ...state,
          networkInformation: {
            ...state.networkInformation,
            type,
            id,
            imageIndex,
          },
        };
      }
      return state;
    },
  },
});

export const {
  addLoungeParticipant,
  removeLoungeParticipant,
  setWhereByOpenStatus,
  getIsCallWaitingStatus,
  getParticipantName,
  incomingVideoCallInfo,
  getUserInformation,
  setWhereByMeetingUrl,
  getUserHiveId,
  getVideoConference,
  getLoungeSceneId,
  getIsCallRejected,
  getIsCallRegretted,
  getChatTranslations,
  getProfilePicture,
  removeAllLoungeParticipants,
  getNetworkUserInformation,
  getLoginToken,
  getNetworkInformation,
} = loungeSlice.actions;

export default loungeSlice.reducer;
