import { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import {
  getChatRoomOpenStatus,
  removeNewMessageNotification,
} from './slices/chatSlice';
import ChatMessageSender from './ChatMessageSender';
import styles from './chatRoom.module.scss';

const ChatRoom = () => {
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [conversationHistory, setConversationHistory] = useState([]);
  const [chatTitle, setChatTitle] = useState<string>('');

  const useRoomInformation = useAppSelector(
    (state) => state.chatSlice.roomInformation
  );

  const useGetOpenedChatRoomId = useAppSelector(
    (state) => state.chatSlice.getOpenedChatRoomId
  );

  useEffect(() => {
    if (useGetOpenedChatRoomId === useRoomInformation.roomId) {
      setConversationHistory(
        //@ts-ignore
        useRoomInformation?.timeline?.filter(
          (item: any) => item?.event?.type === 'm.room.message'
        )
      );
      //@ts-ignore
      setChatTitle(useRoomInformation.name);
      scrollIntoViewHandler();

      dispatch(removeNewMessageNotification(useRoomInformation.roomId));
    }
    return;
  }, [useRoomInformation]);

  const closeChatRoomHandler = () => {
    dispatch(getChatRoomOpenStatus(false));
  };

  const scrollIntoViewHandler = () => {
    bottomRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  };

  useEffect(() => {
    setTimeout(scrollIntoViewHandler, 100);
  }, []);

  return (
    <motion.div
      className={styles.chatRoomWrapper}
      initial='collapsed'
      animate='open'
      variants={{
        open: {
          width: '100%',
        },
        collapsed: { width: 0 },
      }}
      transition={{
        duration: 0.4,
      }}
    >
      <div className={styles.chatRoomHeader}>
        <motion.div
          initial='collapsed'
          animate='open'
          variants={{
            open: {
              opacity: 1,
              height: '20px',
            },
            collapsed: { opacity: 0, height: '20px' },
          }}
          transition={{
            duration: 1,
          }}
        >
          <div
            data-testid='close-chatRoom'
            onClick={closeChatRoomHandler}
            className={styles.chatRoomExit}
          ></div>
          <div className={styles.chatRoomTitle}>{chatTitle}</div>
        </motion.div>
      </div>
      <motion.div
        className={styles.messageHistoryWrapper}
        initial='collapsed'
        animate='open'
        exit='collapsed'
        variants={{
          open: {
            opacity: 1,
          },
          collapsed: { opacity: 0 },
        }}
        transition={{
          duration: 1,
        }}
      >
        <>
          {conversationHistory.map((item: any) => {
            return (
              <div key={item.event.event_id}>
                <div className={styles.senderMe}>
                  {item.sender.rawDisplayName}
                </div>
                <div className={styles.messageMe}>
                  {item.event.content.body}
                </div>
              </div>
            );
          })}
          <div ref={bottomRef}></div>
        </>
      </motion.div>
      <ChatMessageSender scrollIntoViewHandler={scrollIntoViewHandler} />
    </motion.div>
  );
};

export default ChatRoom;
