import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface XpClientDataSliceState {
  fullProgram: any;
  carouselItems?: { logo: string; code: string }[];
  filter: {}[];
  teaser?: { [key: number]: { html: string; url: string } };
  soMeIcons: { image: string }[];
  soMeLinks: { link: string }[];
  programText: { text: string; allowed: boolean };
  galleryText: string;
  hiveName: string;
  loginUserId: string;
  hiveId: string;
  translations: {
    PROGRAM_NO_PROGRAMS_TODAY?: string;
    PROGRAM?: string;
    PROGRAM_ALL_PROGRAMS?: string;
  };
}

const initialState: XpClientDataSliceState = {
  fullProgram: [],
  carouselItems: [],
  filter: [],
  teaser: { [0]: { html: '', url: '' } },
  soMeIcons: [],
  soMeLinks: [],
  programText: { text: '', allowed: false },
  galleryText: '',
  hiveName: '',
  translations: {},
  hiveId: '',
  loginUserId: '',
};

interface XpClientDataSlicePayLoad {
  fullProgram: { filter: (day: {}) => {} };
  topVideo?: { html: string; url: string };
  exhibition?: { logo: string; code: string }[];
  soMeIcons: [];
  soMeLinks: [];
  noProgramText: string;
  allowProgramText: boolean;
  galleryText: string;
  hiveName: string;
  xpTranslations: {};
  hiveId: string;
  loginUserId: string;
}

export const xpClientDataSlice = createSlice({
  name: 'clientData',
  initialState,
  reducers: {
    getProgram: (state, action: PayloadAction<XpClientDataSlicePayLoad>) => {
      if (action.payload && action.payload.fullProgram) {
        let today = new Date();
        return {
          ...state,
          fullProgram: action.payload.fullProgram.filter(
            (day: { startDate: string }) => {
              return day.startDate.includes(today.toISOString().split('T')[0]);
            }
          ),
        };
      }

      // filter away programs that are not in todays schedule
    },
    getTeaser: (state, action: PayloadAction<XpClientDataSlicePayLoad>) => {
      return {
        ...state,
        teaser: action.payload.topVideo,
      };
    },
    getCarouselItems: (
      state,
      action: PayloadAction<XpClientDataSlicePayLoad>
    ) => {
      return { ...state, carouselItems: action.payload.exhibition };
    },
    getSoMeIcons: (state, action: PayloadAction<XpClientDataSlicePayLoad>) => {
      return { ...state, soMeIcons: action.payload.soMeIcons };
    },
    getSoMeLinks: (state, action: PayloadAction<XpClientDataSlicePayLoad>) => {
      return { ...state, soMeLinks: action.payload.soMeLinks };
    },
    getProgramText: (
      state,
      action: PayloadAction<XpClientDataSlicePayLoad>
    ) => {
      return {
        ...state,
        programText: {
          text: action.payload.noProgramText,
          allowed: action.payload.allowProgramText,
        },
      };
    },
    getGalleryText: (
      state,
      action: PayloadAction<XpClientDataSlicePayLoad>
    ) => {
      return { ...state, galleryText: action.payload.galleryText };
    },
    getHiveName: (state, action: PayloadAction<XpClientDataSlicePayLoad>) => {
      return { ...state, hiveName: action.payload.hiveName };
    },
    getHiveId: (state, action: PayloadAction<XpClientDataSlicePayLoad>) => {
      return { ...state, hiveName: action.payload.hiveId };
    },
    getXpTranslations: (
      state,
      action: PayloadAction<XpClientDataSlicePayLoad>
    ) => {
      return { ...state, translations: action.payload.xpTranslations };
    },
    getLoginUserId: (
      state,
      action: PayloadAction<XpClientDataSlicePayLoad>
    ) => {
      return { ...state, loginUserId: action.payload.loginUserId };
    },
  },
});

export const {
  getProgram,
  getCarouselItems,
  getTeaser,
  getSoMeIcons,
  getSoMeLinks,
  getProgramText,
  getGalleryText,
  getHiveName,
  getXpTranslations,
  getHiveId,
  getLoginUserId,
} = xpClientDataSlice.actions;

export default xpClientDataSlice.reducer;
