import { useCallback, useEffect, useState } from 'react';
import {
  useEstablishChatConnectionMutation,
  useDeleteAuthTokenMutation,
} from './slices/usersApiSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  getUserLoginId,
  getMatrixClient,
  getHiveId,
  getAccessToken,
  getRoomInformation,
  getNewMessageNotification,
  getRooms,
  sortListAfterLastActivity,
  getOnlineUsers,
  removeOnlineUsers,
  getNetworkCardId,
  isChatListUpdateNeeded,
  getChatRoomOpenStatus,
  getChatTranslations,
} from './slices/chatSlice';
import { REACT_APP_MATRIX_DOMAIN, REACT_APP_SYNAPSE_URL } from '../../config';
import * as sdk from 'matrix-js-sdk';
import ChatSearchBar from './ChatSearchBar';
import ChatList from './ChatList';
import styles from './chatPanel.module.scss';
import MatrixError from './MatrixError';

const ChatPanel = () => {
  const dispatch = useAppDispatch();
  const [trigger] = useDeleteAuthTokenMutation();
  const [isMatrixClientSynced, setIsMatrixClientSynced] =
    useState<boolean>(false);
  const useLoginUserId = useAppSelector((state) => state.chatSlice.loginUserId);

  const [isAccessTokenExisiting, setIsAccessTokenExisting] = useState(false);
  const [isUserLoggedOut, setIsUserLoggedOut] = useState(false);
  const [hasMatrixFailed, setHasMatrixFailed] = useState(false);

  const [establishChatConnection, { data: accessTokenData }] =
    useEstablishChatConnectionMutation();

  const verifyAccessTokenHandler = (token: string) => {
    token ? setIsAccessTokenExisting(true) : setIsAccessTokenExisting(false);
  };

  const onMessageReceivedFromParent = useCallback(
    (e: {
      origin: string;
      data: {
        hiveId: string;
        loginUserId: string;
        isChatOpen: boolean;
        networkCardId: string;
        isLoggedOut: boolean;
        chatTranslations: {};
      };
    }) => {
      const {
        loginUserId,
        hiveId,
        networkCardId,
        isLoggedOut,
        chatTranslations,
      } = e.data;

      if (loginUserId && hiveId) {
        establishChatConnection({
          userLoginId: loginUserId,
          hiveId,
          post: {},
        });
        dispatch(getUserLoginId(loginUserId));
        dispatch(getHiveId(hiveId));
      }

      if (networkCardId) {
        dispatch(getNetworkCardId(networkCardId));
      }

      if (chatTranslations) {
        dispatch(getChatTranslations(chatTranslations));
      }

      if (isLoggedOut) {
        setIsUserLoggedOut(true);
        setIsAccessTokenExisting(false);
      } else {
        setIsUserLoggedOut(false);
      }
    },
    []
  );

  useEffect(() => {
    verifyAccessTokenHandler(accessTokenData?.accessToken);
  }, [accessTokenData]);

  useEffect(() => {
    const handler = onMessageReceivedFromParent;
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [onMessageReceivedFromParent]);

  const newMessageRecievedHandler = useCallback(
    (matrixClient: any, roomId: string) => {
      dispatch(getRoomInformation(matrixClient.getRoom(roomId)));
      dispatch(getNewMessageNotification(roomId));
      const roomsArray = matrixClient.getVisibleRooms();
      dispatch(sortListAfterLastActivity(roomsArray));
      dispatch(getRooms(roomsArray));
      window.parent.postMessage({ newMessageRecieved: true }, '*');
    },
    [] 
  );

  const autoJoinRoomHandler = useCallback(
    (matrixClient: any, roomId: string) => {
      dispatch(isChatListUpdateNeeded(false));
      matrixClient.joinRoom(roomId).then(() => {
        dispatch(isChatListUpdateNeeded(true));
      });
    },
    []
  );

  useEffect(() => {
    if (
      useLoginUserId.length > 0 &&
      isAccessTokenExisiting &&
      !isUserLoggedOut
    ) {
      dispatch(getAccessToken(accessTokenData.accessToken));
      const client = sdk.createClient({
        baseUrl: REACT_APP_SYNAPSE_URL,
        accessToken: accessTokenData.accessToken,
        userId: `@${useLoginUserId}:${REACT_APP_MATRIX_DOMAIN}`,
      });

      //@ts-ignore
      client.once('sync', function (state, prevState, data) {
        switch (state) {
          case 'PREPARED':
            client.on(
              //@ts-ignore
              'Room.timeline',
              //@ts-ignore
              function (event, room, toStartOfTimeline) {
                // we know we only want to respond to messages
                if (event.getType() !== 'm.room.message') {
                  return;
                }
                newMessageRecievedHandler(client, room.roomId);
              }
            );

            //@ts-ignore
            client.on('RoomMember.membership', function (event, member) {
              if (
                member.membership === 'invite' &&
                member.userId ===
                  `@${useLoginUserId}:${REACT_APP_MATRIX_DOMAIN}`
              ) {
                autoJoinRoomHandler(client, member.roomId);
              }
            });
            const rooms = client.getVisibleRooms();
            rooms.map((room) => {
              //@ts-ignore
              if (room.selfMembership === 'invite') {
                return client.joinRoom(room.roomId);
              }
            });
            setIsMatrixClientSynced(true);
            break;
          case 'ERROR':
            setHasMatrixFailed(true);
            break;
        }
      });

      //@ts-ignore
      client.on('User.presence', function (event, user) {
        if (user.userId === `@${useLoginUserId}:${REACT_APP_MATRIX_DOMAIN}`)
          return;
        if (user.currentlyActive) {
          dispatch(getOnlineUsers(user.userId));
        } else {
          dispatch(removeOnlineUsers(user.userId));
        }
      });

      client.startClient({ initialSyncLimit: 50 });
      dispatch(getMatrixClient(client));
    } else if (
      useLoginUserId.length > 0 &&
      isUserLoggedOut &&
      !isAccessTokenExisiting
    ) {
      trigger({ authToken: accessTokenData.accessToken });
      setIsMatrixClientSynced(false);
      dispatch(getChatRoomOpenStatus(false));
    }

    // isMatrixClientSynced needs to be listened for in order for chatroom conversations to be updated correctly in Safari and Firefox
  }, [
    useLoginUserId,
    isAccessTokenExisiting,
    isMatrixClientSynced,
    hasMatrixFailed,
  ]);

  return (
    <div className={styles.chatPanelWrapper}>
      {hasMatrixFailed ? (
        <>
          <MatrixError setHasMatrixFailed={setHasMatrixFailed} />
        </>
      ) : (
        <>
          <ChatSearchBar />
          <ChatList isMatrixClientSynced={isMatrixClientSynced} />
        </>
      )}
    </div>
  );
};

export default ChatPanel;
