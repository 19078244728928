import { routerType } from './router.types';
import App from '../../App';
import ExperiencePanel from '../experiencePanel/ExPanel';
import ChatPanel from '../chat/ChatPanel';
import VideoChatPanel from '../videoChat/VideoChatPanel';
import LoungePanel from '../lounge/loungePanel/LoungePanel';

const routesData: routerType[] = [
  {
    path: '',
    element: <App />,
    key: 'app',
  },
  {
    path: 'experience-panel',
    element: <ExperiencePanel />,
    key: 'experience-panel',
  },
  {
    path: 'chat',
    element: <ChatPanel />,
    key: 'chat',
  },
  {
    path: 'video-chat',
    element: <VideoChatPanel />,
    key: 'video-chat',
  },
  {
    path: 'lounge',
    element: <LoungePanel />,
    key: 'lounge',
  },
];

export default routesData;
