import { useAppSelector } from '../../hooks/reduxHooks';
import { motion, AnimatePresence } from 'framer-motion';
import { formatInTimeZone } from 'date-fns-tz';
import styles from './exPanelProgramList.module.scss';
import { isAfter, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';

interface Props {
  customStyling?: {
    program: string;
    noContentTitle: string;
    programWrapperFullProgram: string;
  };
  isLive: boolean;
}

const ExPanelProgramList = ({ customStyling, isLive }: Props) => {
  const fullProgramSelector = useAppSelector(
    (state) => state.xpSlice.fullProgram
  );

  const { PROGRAM_NO_PROGRAMS_TODAY } = useAppSelector(
    (state) => state.xpSlice.translations
  );
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const goToSceneHandler = (pageId: string) => {
    window.parent.postMessage({ goToScene: pageId }, '*');
  };

  const [filteredPrograms, setFilteredPrograms] = useState([]);

  useEffect(() => {
    const filterPrograms = () => {
      const today = new Date();
      setFilteredPrograms(
        fullProgramSelector.filter(
          (program: { startDate: string; endDate: string }) => {
            const startDate = formatInTimeZone(
              `${program.startDate}Z`,
              timeZone,
              'yyyy-MM-dd HH:mm'
            );
            const endDate = formatInTimeZone(
              `${program.endDate}Z`,
              timeZone,
              'yyyy-MM-dd HH:mm'
            );
            const isProgramLive =
              isAfter(today, new Date(startDate)) &&
              isBefore(today, new Date(endDate)) &&
              isLive;
            return !isLive || isProgramLive;
          }
        )
      );
    };

    filterPrograms();

    const interval = setInterval(filterPrograms, 5 * 60 * 1000); // call filterPrograms every 5 minutes

    return () => clearInterval(interval);
  }, [fullProgramSelector, isLive, timeZone]);

  return (
    <motion.div
      className={`${styles.programWrapperFullProgram} ${customStyling?.programWrapperFullProgram}`}
      initial={{ x: 200, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -200, opacity: 0 }}
    >
      {filteredPrograms.length > 0 ? (
        filteredPrograms.map(
          (program: {
            startDate: string;
            endDate: string;
            pageId: string;
            id: string;
            page: { title: string };
          }) => {
            return (
              <AnimatePresence key={program.id}>
                <motion.div
                  layout
                  className={`${isLive ? styles.liveProgram : styles.program} ${
                    customStyling?.program
                  }`}
                  whileHover={{ y: -4 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => goToSceneHandler(program.pageId)}
                >
                  <div>
                    {`${formatInTimeZone(
                      `${program.startDate}Z`,
                      timeZone,
                      'HH:mm'
                    )} - ${formatInTimeZone(
                      `${program.endDate}Z`,
                      timeZone,
                      'HH:mm'
                    )}`}
                  </div>
                  <div
                    className={styles.programText}
                    title={program.page.title}
                  >
                    {program.page.title}
                  </div>
                </motion.div>
              </AnimatePresence>
            );
          }
        )
      ) : (
        <div className={styles.noContentWrapper}>
          <div
            className={`${styles.noContentTitle} ${customStyling?.noContentTitle}`}
          >
            {isLive
              ? 'There are currently no live programs'
              : PROGRAM_NO_PROGRAMS_TODAY}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default ExPanelProgramList;
