declare var process: {
  env: {
    REACT_APP_CDN_URL: string;
    REACT_APP_CHAT_SERVICE_URL: string;
    REACT_APP_SYNAPSE_URL: string;
    REACT_APP_MATRIX_DOMAIN: string;
    REACT_APP_API_SERVICE_URL: string;
    NODE_ENV: string;
  };
};

let REACT_APP_CDN_URL: string;
let REACT_APP_CHAT_SERVICE_URL: string;
let REACT_APP_SYNAPSE_URL: string;
let REACT_APP_MATRIX_DOMAIN: string;
let REACT_APP_API_SERVICE_URL: string;

//the production env variables is not in a env.production file as env files cannot take in the window object which is required in our production setup.
if (process.env.NODE_ENV === 'production') {
  REACT_APP_CDN_URL = window.REACT_APP_CDN_URL;
  REACT_APP_CHAT_SERVICE_URL = window.REACT_APP_CHAT_SERVICE_URL;
  REACT_APP_SYNAPSE_URL = window.REACT_APP_SYNAPSE_URL;
  REACT_APP_MATRIX_DOMAIN = window.REACT_APP_MATRIX_DOMAIN;
  REACT_APP_API_SERVICE_URL = window.REACT_APP_API_SERVICE_URL;
} else {
  REACT_APP_CDN_URL = process.env.REACT_APP_CDN_URL;
  REACT_APP_CHAT_SERVICE_URL = process.env.REACT_APP_CHAT_SERVICE_URL;
  REACT_APP_SYNAPSE_URL = process.env.REACT_APP_SYNAPSE_URL;
  REACT_APP_MATRIX_DOMAIN = process.env.REACT_APP_MATRIX_DOMAIN;
  REACT_APP_API_SERVICE_URL = process.env.REACT_APP_API_SERVICE_URL;
}

export {
  REACT_APP_CDN_URL,
  REACT_APP_CHAT_SERVICE_URL,
  REACT_APP_SYNAPSE_URL,
  REACT_APP_MATRIX_DOMAIN,
  REACT_APP_API_SERVICE_URL,
};
