import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { REACT_APP_CDN_URL } from '../../config';
import styles from './expanelTeaser.module.scss';

const ExPanelTeaser = () => {
  const [fetchedUrl, setFetchedUrl] = useState('');
  const [isURLVideo, setIsURLVideo] = useState(false);
  const useTeaser = useAppSelector((state) => state.xpSlice.teaser);

  useEffect(() => {
    if (!useTeaser || !useTeaser[0]) return;
    const { html, url } = useTeaser[0];
    if (html) {
      setIsURLVideo(true);
      setFetchedUrl(html.slice(html.indexOf('src')).split('"')[1]);
    } else if (url) {
      setIsURLVideo(false);
      const replaceCDN = url.replace('{cdn_url}', `${REACT_APP_CDN_URL}`);
      setFetchedUrl(replaceCDN);
    }
  }, [useTeaser]);

  return (
    <div className={styles.contentWrapper}>
      {isURLVideo ? (
        <iframe
          className={styles.video}
          src={fetchedUrl}
          title='teaserIframe'
        />
      ) : (
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${fetchedUrl}`,
          }}
        ></div>
      )}
    </div>
  );
};

export default ExPanelTeaser;
