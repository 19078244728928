import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Room {
  getMembers(): void;
  name: string;
  roomId: string;
}

interface ChatSliceState {
  roomInformation: { name?: string; roomId?: string };
  loginUserId: string;
  matrixClient: any;
  rooms: Room[];
  isChatRoomOpen: boolean;
  hiveId: string;
  accessToken: string;
  newMessageNotification: string[];
  onlineUsers: string[];
  networkCardId: string;
  isChatListUpdateNeeded: boolean;
  getOpenedChatRoomId: string;
  translations: {
    LATIN_REQUIREMENT?: string;
    CHAT_CREATE_NEW?: string;
    CHAT_JOIN_EXISTING?: string;
    CHAT_ROOMS?: string;
    CHAT_SEARCH_FIRSTNAME?: string;
    COULD_NOT_FIND_PERSON?: string;
  };
}

interface roomInformationPayLoad {
  room: {};
  name: string;
}

const initialState: ChatSliceState = {
  roomInformation: {},
  loginUserId: '',
  matrixClient: {},
  rooms: [],
  isChatRoomOpen: false,
  hiveId: '',
  accessToken: '',
  newMessageNotification: [],
  onlineUsers: [],
  networkCardId: '',
  isChatListUpdateNeeded: false,
  getOpenedChatRoomId: '',
  translations: {},
};

export const chatSlice = createSlice({
  name: 'chatData',
  initialState,
  reducers: {
    getRoomInformation: (
      state,
      action: PayloadAction<roomInformationPayLoad>
    ) => {
      return {
        ...state,
        roomInformation: action.payload,
      };
    },
    getUserLoginId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        loginUserId: action.payload,
      };
    },
    getMatrixClient: (state, action: PayloadAction<{}>) => {
      return {
        ...state,
        matrixClient: action.payload,
      };
    },
    getRooms: (state, action: PayloadAction<[]>) => {
      return {
        ...state,
        rooms: action.payload,
      };
    },
    getChatRoomOpenStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isChatRoomOpen: action.payload,
      };
    },
    getHiveId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        hiveId: action.payload,
      };
    },
    getAccessToken: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        accessToken: action.payload,
      };
    },
    getNewMessageNotification: (state, action: PayloadAction<string>) => {
      if (!state.newMessageNotification.includes(action.payload))
        state.newMessageNotification.push(action.payload);
    },
    removeNewMessageNotification: (state, action: PayloadAction<string>) => {
      const newArrayOfExisitingNotifications =
        state.newMessageNotification.filter(
          (roomid) => roomid !== action.payload
        );

      state.newMessageNotification = newArrayOfExisitingNotifications;
    },
    getOnlineUsers: (state, action: PayloadAction<string>) => {
      if (!state.onlineUsers.includes(action.payload))
        state.onlineUsers.push(action.payload);
    },
    removeOnlineUsers: (state, action: PayloadAction<string>) => {
      const newArrayOfOnlineUsers = state.onlineUsers.filter(
        (userId) => userId !== action.payload
      );

      state.onlineUsers = newArrayOfOnlineUsers;
    },
    sortListAfterLastActivity: (state, action: PayloadAction<[]>) => {
      action.payload.sort(
        (a: { timeline: string | any }, b: { timeline: string | any }) => {
          const aMsg = a.timeline[a.timeline.length - 1];
          const bMsg = b.timeline[b.timeline.length - 1];

          if (!aMsg || !bMsg) {
            return !aMsg ? 1 : -1;
          }

          return bMsg.getTs() - aMsg.getTs();
        }
      );
    },
    getNetworkCardId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        networkCardId: action.payload,
      };
    },
    isChatListUpdateNeeded: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isChatListUpdateNeeded: action.payload,
      };
    },
    getOpenedChatRoomId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        getOpenedChatRoomId: action.payload,
      };
    },
    getChatTranslations: (state, action: PayloadAction<{}>) => {
      return {
        ...state,
        translations: action.payload,
      };
    },
  },
});

export const {
  getRoomInformation,
  getUserLoginId,
  getMatrixClient,
  getRooms,
  getChatRoomOpenStatus,
  getHiveId,
  getAccessToken,
  getNewMessageNotification,
  removeNewMessageNotification,
  sortListAfterLastActivity,
  getOnlineUsers,
  removeOnlineUsers,
  getNetworkCardId,
  isChatListUpdateNeeded,
  getOpenedChatRoomId,
  getChatTranslations,
} = chatSlice.actions;

export default chatSlice.reducer;
