import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import styles from './loungeDeclinedCard.module.scss';
import {
  getIsCallRejected,
  getIsCallWaitingStatus,
} from '../slices/loungeSlice';

const LoungeDeclinedCard = () => {
  const dispatch = useAppDispatch();
  const wrapperVariants = {
    hidden: { scale: 0.3, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  const closeHandler = () => {
    dispatch(getIsCallRejected(false));
    dispatch(getIsCallWaitingStatus(false));
  };

  const { VIDEO_CHAT_CLEAR, VIDEO_CHAT_CALL_WAS_DECLINED } = useAppSelector(
    (state) => state.loungeSlice.translations
  );

  return (
    <motion.div
      variants={wrapperVariants}
      initial='hidden'
      animate='visible'
      transition={{ duration: 0.5, delay: 1 }}
      exit={{
        scale: 0.3,
        opacity: 0,
        transition: {
          delay: 0.3,
          duration: 0.5,
        },
      }}
      className={styles.declinedWrapper}
    >
      <div className={styles.participantBackground}></div>
      <div className={styles.declinedNotification}>
        {VIDEO_CHAT_CALL_WAS_DECLINED}
      </div>
      <motion.div
        whileHover={{ scale: 1.04 }}
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.4 }}
        className={styles.close}
        onClick={closeHandler}
      >
        {VIDEO_CHAT_CLEAR}
      </motion.div>
    </motion.div>
  );
};

export default LoungeDeclinedCard;
