import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './components/routes/AppRoutes';
import 'normalize.css';

if (process.env.NODE_ENV !== 'development')
  document.domain = 'virtualhiveservices.io';
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <AppRoutes />
  </BrowserRouter>
);
