import { REACT_APP_CDN_URL } from '../../config';
import { useAppSelector } from '../../hooks/reduxHooks';
import styles from './exPanelSoMe.module.scss';
import { v4 as uuidv4 } from 'uuid';
interface Props {
  customStyling?: {
    iconContainer: string;
  };
}

const ExPanelSoMe = ({ customStyling }: Props) => {
  const soMeIconsSelector = useAppSelector((state) => state.xpSlice.soMeIcons);

  const soMeLinksSelector = useAppSelector((state) => state.xpSlice.soMeLinks);

  const iconsAndLinks = Object.keys(soMeIconsSelector).map((key: any) => {
    return (
      <div className={styles.iconAndLinkWrapper} key={uuidv4()}>
        <a href={soMeLinksSelector[key]?.link} target='_blank' rel='noreferrer'>
          <div
            className={styles.icon}
            style={{
              backgroundImage: `url(${soMeIconsSelector[key].image?.replace(
                '{cdn_url}',
                `${REACT_APP_CDN_URL}`
              )}`,
            }}
          ></div>
        </a>
      </div>
    );
  });

  return (
    <div className={`${styles.iconContainer} ${customStyling?.iconContainer}`}>
      {iconsAndLinks}
    </div>
  );
};

export default ExPanelSoMe;
