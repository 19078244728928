import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  getIsCallWaitingStatus,
  setWhereByMeetingUrl,
  getParticipantName,
  getNetworkUserInformation,
  getNetworkInformation,
} from "../slices/loungeSlice";
import {
  useCallParticipantMutation,
  useAddParticipantMutation,
} from "../slices/loungeApiSlice";
import { useLazyGetParticipantInformationQuery } from "../slices/loungeLoginApiSlice";
import ProfileImagePlaceholder from "../../profileImagePlaceholder/ProfileImagePlaceholder";
import styles from "../loungeParticipants/loungeParticipants.module.scss";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import React from "react";

interface Props {
  participant: {
    userId: string;
    userName: string;
  };
  you: boolean;
}

const LoungeParticipantCard = ({ participant, you }: Props) => {
  const { userId, userName } = participant;
  const dispatch = useAppDispatch();

  const [toUserId, setToUserId] = useState("");
  const [toUserName, setToUserName] = useState("");
  const useUserInformation = useAppSelector(
    (state) => state.loungeSlice.userInformation
  );

  const useLoginToken = useAppSelector((state) => state.loungeSlice.loginToken);

  const useHiveId = useAppSelector(
    (state) => state.loungeSlice.loginUserHiveId
  );

  const useLoungeSceneId = useAppSelector(
    (state) => state.loungeSlice.loungeSceneId
  );

  const useParticipantLoginData = useAppSelector(
    (state) => state.loungeSlice.networkUserInformation.loginData
  );

  const [
    getParticipantInformationTrigger,
    {
      data: participantInformationData,
      isSuccess: isParticipantInformationFetched,
      isFetching: isParticipantInformationBeingFetched,
    },
  ] = useLazyGetParticipantInformationQuery();

  const [callParticipantTrigger, { data: whereByMeetingData }] =
    useCallParticipantMutation();

  const [addParticipantTrigger] = useAddParticipantMutation();

  const awaitCallResponseHandler = (
    userId: string,
    participantName: string
  ) => {
    setToUserId(userId);
    setToUserName(participantName);
    dispatch(getParticipantName(participantName));
    dispatch(getIsCallWaitingStatus(true));
    callParticipantTrigger({
      postData: {
        toUserId: userId,
        toUserName: participantName,
        fromUserName: useUserInformation.loginUserName,
        fromUserId: useUserInformation.loginUserId,
        public: true,
      },
      hiveId: useHiveId,
      loungeSceneId: useLoungeSceneId,
      loginToken: useLoginToken,
    });
  };

  useEffect(() => {
    if (whereByMeetingData?.id) {
      dispatch(setWhereByMeetingUrl(whereByMeetingData.id));

      addParticipantTrigger({
        postData: {
          userId: toUserId,
          userName: toUserName,
          status: "APPROACHED",
        },
        videoConferenceId: whereByMeetingData.id,
        hiveId: useHiveId,
        loungeSceneId: useLoungeSceneId,
        loginToken: useLoginToken,
      });
    }
  }, [whereByMeetingData]);

  useEffect(() => {
    if (
      isParticipantInformationFetched &&
      !isParticipantInformationBeingFetched
    ) {
      dispatch(getNetworkUserInformation(participantInformationData));
    }
  }, [
    dispatch,
    isParticipantInformationFetched,
    participantInformationData,
    isParticipantInformationBeingFetched,
  ]);

  const getNetworkUserInformationHandler = (
    participantId: string,
    hiveId: string
  ) => {
    getParticipantInformationTrigger({
      participantId,
      hiveId,
      loginToken: useLoginToken,
    });
  };

  const handleCardClick = (userId: string) => {
    dispatch(getNetworkInformation({ type: "participant", id: userId }));
    getNetworkUserInformationHandler(userId, useHiveId);
  };

  const motionDivProps = {
    layout: true,
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5 },
    onClick: () => handleCardClick(userId),
  };

  return (
    <Tooltip
      title={
        <React.Fragment>
          <Typography variant="body1">{participant.userName}</Typography>
          {/* Iterate through useParticipantLoginData */}
          {/* {useParticipantLoginData?.map((field: any, index: any) => (
            <Typography key={index} variant="body2">
              {`${field.visitorField.identifier}: ${field.value}`}
            </Typography>
          ))} */}
        </React.Fragment>
      }
      placement="right"
      arrow
    >
      <motion.div
        className={`${styles.participantCard} ${
          you ? styles.participantCardYou : ""
        }`}
        key={participant.userId}
        {...motionDivProps}
      >
        <ProfileImagePlaceholder
          className={styles.placeholderImage}
          userName={userName}

        />
        <div className={styles.participantInfoWrapper}>
          <div className={styles.name}>{participant.userName}</div>
        </div>
      </motion.div>
    </Tooltip>
  );
};

export default LoungeParticipantCard;
