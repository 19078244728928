import styles from "./loader.module.scss";

const Loader = () => {
  return (
    <div className={styles.imageWrapper}>
      <div className={styles.loadingImage}>
        <div className={styles.yellow}></div>
        <div className={styles.green}></div>
        <div className={styles.blue}></div>
      </div>
    </div>
  );
};

export default Loader;
