import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  getIsCallWaitingStatus,
  setWhereByOpenStatus,
  incomingVideoCallInfo,
} from '../slices/loungeSlice';
import {
  useAcceptCallMutation,
  useRemoveParticipantMutation,
  useUpdateParticipantStatusMutation,
} from '../slices/loungeApiSlice';
import { useAppSelector, useAppDispatch } from '../../../hooks/reduxHooks';
import styles from './loungeIncomingCallCard.module.scss';

const LoungeIncomingCallCard = () => {
  const dispatch = useAppDispatch();
  const [acceptCallTrigger] = useAcceptCallMutation();
  const [updateParticipantStatusTrigger] = useUpdateParticipantStatusMutation();
  const [removeParticipantTrigger] = useRemoveParticipantMutation();
  const videoCallInfo = useAppSelector(
    (state) => state.loungeSlice.videoCallInfo
  );

  const hiveId = useAppSelector(
    (state) => state.loungeSlice.loginUserHiveId
  );

  const loungeSceneId = useAppSelector(
    (state) => state.loungeSlice.loungeSceneId
  );

  const profilePicture = useAppSelector(
    (state) => state.loungeSlice.profilePicture
  );

  const userInformation = useAppSelector(
    (state) => state.loungeSlice.userInformation
  );

  const [persistfromUserName, setPersistfromUserName] = useState('');

  const { VIDEO_CHAT_ACCEPT_CALL, VIDEO_CHAT_DECLINE_CALL } = useAppSelector(
    (state) => state.loungeSlice.translations
  );

  const wrapperVariants = {
    hidden: { scale: 0.3, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  const declineCallHandler = () => {
    dispatch(incomingVideoCallInfo(''));
    removeParticipantTrigger({
      videoConferenceId: videoCallInfo.id,
      participantId: userInformation.loginUserId,
      hiveId,
      loungeSceneId,
    });
  };

  const acceptCallHandler = () => {
    acceptCallTrigger({
      putData: {
        toUserId: videoCallInfo.fromUserId,
        toUserName: videoCallInfo.fromUserName,
        fromUserName: userInformation.loginUserName,
        fromUserId: userInformation.loginUserId,
        action: 'accept',
      },
      videoConferenceId: videoCallInfo.id,
      hiveId,
    });

    updateParticipantStatusTrigger({
      putData: {
        userId: userInformation.loginUserId,
        userName: userInformation.loginUserName,
        profilePicture: profilePicture,
        status: 'JOINED',
      },
      videoConferenceId: videoCallInfo.id,
      userId: userInformation.loginUserId,
      hiveId,
      loungeSceneId,
    });
    dispatch(setWhereByOpenStatus(true));
    dispatch(incomingVideoCallInfo(''));
    dispatch(getIsCallWaitingStatus(false));
  };

  useEffect(() => {
    if (videoCallInfo.fromUserName) {
      //to avoid the name being removed immediately when running the exit animation
      setPersistfromUserName(videoCallInfo.fromUserName);
    }
  }, [videoCallInfo.fromUserName]);

  return (
    <motion.div
      variants={wrapperVariants}
      initial='hidden'
      animate='visible'
      transition={{ duration: 0.5 }}
      exit={{
        scale: 0.3,
        opacity: 0,
        transition: {
          delay: 0.3,
          duration: 0.5,
        },
      }}
      className={styles.recieveWrapper}
    >
      <div className={styles.participantBackground}></div>
      <motion.div
        variants={wrapperVariants}
        transition={{ delay: 0.3, duration: 0.5 }}
        exit={{ opacity: 0, scale: 0.3, transition: { duration: 0.5 } }}
        className={styles.callIconWrapper}
      >
        <div className={styles.callIcon}></div>
      </motion.div>
      <div className={styles.participantNameWrapper}>
        <div className={styles.calling}>Incoming call</div>
        <div className={styles.participantName}>{persistfromUserName}</div>
      </div>
      <div className={styles.buttonWrapper}>
        <motion.div
          whileHover={{ scale: 1.04 }}
          whileTap={{ scale: 0.9 }}
          transition={{ duration: 0.4 }}
          className={styles.acceptCall}
          onClick={acceptCallHandler}
        >
          {VIDEO_CHAT_ACCEPT_CALL}
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.04 }}
          whileTap={{ scale: 0.9 }}
          transition={{ duration: 0.4 }}
          className={styles.declineCall}
          onClick={declineCallHandler}
        >
          {VIDEO_CHAT_DECLINE_CALL}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default LoungeIncomingCallCard;
