import styles from './matrixError.module.scss';
import { motion } from 'framer-motion';

interface Props {
  setHasMatrixFailed: (value: boolean) => void;
}

const MatrixError = ({ setHasMatrixFailed }: Props) => {
  return (
    <div className={styles.matrixFailedWrapper}>
      <div className={styles.matrixFailedPicture}></div>
      <div className={styles.matrixFailedtitle}>
        There was a connection issue, try to click the refresh button or refresh
        the site
        <motion.button
          whileHover={{ scale: 1.06 }}
          whileTap={{ scale: 1 }}
          transition={{ duration: 0.3 }}
          onClick={() => setHasMatrixFailed(false)}
        >
          Refresh
        </motion.button>
      </div>
    </div>
  );
};

export default MatrixError;
