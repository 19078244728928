import LoungeParticipants from "../loungeParticipants/LoungeParticipants";
import LoungeMeetings from "../loungeMeetings/LoungeMeetings";
import LoungeIncomingCallCard from "../loungeIncomingCallCard/LoungeIncomingCallCard";
import LoungeWaitingCard from "../loungeWaitingCard/LoungeWaitingCard";
import { useEffect, useCallback, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import "@whereby.com/browser-sdk";
import {
  addLoungeParticipant,
  removeLoungeParticipant,
  incomingVideoCallInfo,
  getUserInformation,
  setWhereByOpenStatus,
  getIsCallWaitingStatus,
  setWhereByMeetingUrl,
  getUserHiveId,
  getLoungeSceneId,
  getVideoConference,
  getIsCallRejected,
  getIsCallRegretted,
  getChatTranslations,
  getProfilePicture,
  removeAllLoungeParticipants,
  getLoginToken,
} from "../slices/loungeSlice";
import {
  useAddParticipantMutation,
  useGetVideoConferencesMutation,
  useRemoveParticipantMutation,
} from "../slices/loungeApiSlice";
import styles from "./loungePanel.module.scss";
import LoungeDeclinedCard from "../loungeDeclineCard/LoungeDeclinedCard";

const LoungePanel = () => {
  const dispatch = useAppDispatch();

  const isWhereByOpen = useAppSelector(
    (state) => state.loungeSlice.isWhereByOpen
  );

  const loungeSceneId = useAppSelector(
    (state) => state.loungeSlice.loungeSceneId
  );

  const isCallWaiting = useAppSelector(
    (state) => state.loungeSlice.isCallWaiting
  );
  const videoCallInfo = useAppSelector(
    (state) => state.loungeSlice.videoCallInfo
  );

  const userInformation = useAppSelector(
    (state) => state.loungeSlice.userInformation
  );
  const whereByMeetingUrl = useAppSelector(
    (state) => state.loungeSlice.whereByMeetingUrl
  );
  const hiveId = useAppSelector((state) => state.loungeSlice.loginUserHiveId);
  const isCallRejected = useAppSelector(
    (state) => state.loungeSlice.isCallRejected
  );

  const isCallRegretted = useAppSelector(
    (state) => state.loungeSlice.isCallRegretted
  );

  const profilePicture = useAppSelector(
    (state) => state.loungeSlice.profilePicture
  );

  const [addParticipantTrigger] = useAddParticipantMutation();
  const [removeParticipantTrigger] = useRemoveParticipantMutation();
  const [getVideoConferencesTrigger, { data: getVideoConferencesData }] =
    useGetVideoConferencesMutation();

  const onMessageReceivedFromParent = useCallback(
    (e: {
      data: {
        message: {
          action: string;
          data: {
            userName: string;
            userId: string;
            fromUserName: string;
            fromUserId: string;
            deletedBy: string;
          };
        };
        loginUserId: string;
        loginUserName: string;
        loginUserHiveId: string;
        loungeSceneId: string;
        chatTranslations: {};
        profilePicture: string;
        loginToken: string;
        isLoungeLeaved: boolean;
      };
    }) => {
      const {
        message,
        loginUserId,
        loginUserName,
        loginUserHiveId,
        loungeSceneId,
        chatTranslations,
        profilePicture,
        loginToken,
        isLoungeLeaved,
      } = e.data;
      const messageAction = message?.action;
      if (loginUserId && loginUserName) {
        dispatch(getUserInformation({ loginUserId, loginUserName }));
        dispatch(getLoginToken(loginToken));
      }

      if (loginUserHiveId !== undefined) {
        dispatch(getUserHiveId(loginUserHiveId));
      }

      if (isLoungeLeaved !== undefined) {
        dispatch(setWhereByOpenStatus(!isLoungeLeaved));
      }

      if (loungeSceneId !== undefined) {
        dispatch(getLoungeSceneId(loungeSceneId));
      }

      //clean up when leaving lounge. Especially important when there are two lounges that the user alternates between.
      if (
        loungeSceneId === undefined &&
        messageAction === "SceneLeft" &&
        message.data?.userId === loginUserId
      ) {
        dispatch(removeAllLoungeParticipants());
      }

      if (chatTranslations !== undefined) {
        dispatch(getChatTranslations(chatTranslations));
      }

      if (profilePicture !== undefined) {
        dispatch(getProfilePicture(profilePicture));
      }

      if (
        messageAction === "sceneEntered" &&
        loginUserId !== message.data.userId
      ) {
        dispatch(addLoungeParticipant(message.data));
      }
      if (messageAction === "sceneLeft") {
        dispatch(removeLoungeParticipant(message.data.userId));
      }
      if (messageAction === "videoConferenceInit") {
        dispatch(incomingVideoCallInfo(message.data));
        dispatch(setWhereByMeetingUrl(message.data.userId));
        dispatch(getIsCallRegretted(false));
      }

      if (messageAction === "videoConferenceAccept") {
        dispatch(setWhereByOpenStatus(true));
        dispatch(getIsCallWaitingStatus(false));
        addParticipantTrigger({
          postData: {
            userId: userInformation.loginUserId,
            userName: userInformation.loginUserName,
            profilePicture: profilePicture,
            status: "JOINED",
          },
          videoConferenceId: whereByMeetingUrl,
          hiveId,
          loungeSceneId,
        });
      }

      if (
        messageAction === "userJoinedConference" ||
        messageAction === "userLeftConference"
      ) {
        getVideoConferencesTrigger({
          hiveId,
          loungeSceneId,
        });
      }

      if (messageAction === "userRejectedConference") {
        dispatch(getIsCallRejected(true));
      }

      if (messageAction === "videoConferenceDeleted") {
        dispatch(getIsCallRegretted(true));
      }
    },
    [
      dispatch,
      addParticipantTrigger,
      userInformation.loginUserId,
      userInformation.loginUserName,
      whereByMeetingUrl,
      hiveId,
      getVideoConferencesTrigger,
    ]
  );

  const [roomId, setRoomId] = useState("");
  const [isMeetingJoined, setIsMeetingJoined] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    const room = document.querySelector("whereby-embed");

    if (!roomId || !room) {
      return;
    }

    const meetingEvent = (actionType: string) => {
      if (actionType === "meetingEntered") {
        setToggleModal(true);
      } else if (actionType === "meetingLeft") {
        setToggleModal(false);
      }

      const eventDetail = {
        action: actionType,
        identifier: roomId,
      };
      window.parent.postMessage(eventDetail, "*");
    };

    const joinListener = () => {
      meetingEvent("meetingEntered");
    };

    const leaveListener = () => {
      if (!isMeetingJoined) {
        return;
      }
      meetingEvent("meetingLeft");
      setIsMeetingJoined(false);
    };

    room.addEventListener("join", joinListener);
    room.addEventListener("leave", leaveListener);

    return () => {
      room.removeEventListener("join", joinListener);
      room.removeEventListener("leave", leaveListener);
    };
  }, [roomId, isMeetingJoined]);

  const handleMeetingCardId = (id: string) => {
    setRoomId(id);
    console.log(id);
    setIsMeetingJoined(true);
  };

  useEffect(() => {
    const room = document.querySelector("whereby-embed");

    if (room) {
      const leaveHandler = () => {
        dispatch(setWhereByOpenStatus(false));
      };

      room.addEventListener("leave", leaveHandler);

      return () => {
        room.removeEventListener("leave", leaveHandler);
      };
    }
  }, [isWhereByOpen]);

  useEffect(() => {
    if (getVideoConferencesData?.length > 0) {
      dispatch(getVideoConference(getVideoConferencesData));
    } else {
      dispatch(getVideoConference([]));
    }
  }, [
    dispatch,
    removeParticipantTrigger,
    whereByMeetingUrl,
    userInformation.loginUserId,
    hiveId,
    loungeSceneId,
    isWhereByOpen,
    getVideoConferencesData,
  ]);

  useEffect(() => {
    const handler = onMessageReceivedFromParent;
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [onMessageReceivedFromParent]);

  return (
    <div className={styles.lounge}>
      <div className={styles.loungeParticipants}>
        <div className={styles.loungeTopBar}></div>
        <div className={styles.loungeWrapper}>
          {isWhereByOpen && (
            //@ts-ignore
            <whereby-embed
              displayName={userInformation.loginUserName}
              style={{
                height: "100%",
                marginTop: "-56px",
                overflowY: "hidden",
                position: "absolute",
                width: "83%",
                minWidth: "83%",
                zIndex: 100,
              }}
              room={whereByMeetingUrl}
            />
          )}

          <LoungeParticipants />
        </div>
      </div>

      {toggleModal && <div className={styles.modalStyle}></div>}
      <LoungeMeetings onMeetingCardIdUpdate={handleMeetingCardId} />

      {
        <AnimatePresence>
          {isCallWaiting && !isCallRejected && <LoungeWaitingCard />}
        </AnimatePresence>
      }
      {
        <AnimatePresence>
          {isCallRejected && <LoungeDeclinedCard />}
        </AnimatePresence>
      }
      {
        <AnimatePresence>
          {Object.keys(videoCallInfo).length > 0 && !isCallRegretted && (
            <LoungeIncomingCallCard />
          )}
        </AnimatePresence>
      }
    </div>
  );
};

export default LoungePanel;
