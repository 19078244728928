import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  getRoomInformation,
  getRooms,
  getChatRoomOpenStatus,
  removeNewMessageNotification,
  sortListAfterLastActivity,
  getOpenedChatRoomId,
  getNetworkCardId,
} from './slices/chatSlice';
import { REACT_APP_MATRIX_DOMAIN } from '../../config';
import NoChatList from './NoChatList';
import ChatRoom from './ChatRoom';
import Loader from '../loader/Loader';
import styles from './chatList.module.scss';

interface Props {
  isMatrixClientSynced: boolean;
}

const ChatList = ({ isMatrixClientSynced }: Props) => {
  const dispatch = useAppDispatch();

  const [isChatListLoading, setIsChatListLoading] = useState(false);

  const useMatrixClient = useAppSelector(
    (state) => state.chatSlice.matrixClient
  );
  const useNewMessageNotification = useAppSelector(
    (state) => state.chatSlice.newMessageNotification
  );

  const useGetChatRoomOpenStatus = useAppSelector(
    (state) => state.chatSlice.isChatRoomOpen
  );

  const useGetNetworkCardId = useAppSelector(
    (state) => state.chatSlice.networkCardId
  );

  const useIsChatListUpdateNeeded = useAppSelector(
    (state) => state.chatSlice.isChatListUpdateNeeded
  );

  const { CHAT_ROOMS } = useAppSelector(
    (state) => state.chatSlice.translations
  );

  const useGetRooms = useAppSelector((state) => state.chatSlice.rooms);

  const useOnlineUsers = useAppSelector((state) => state.chatSlice.onlineUsers);

  const useLoginUserId = useAppSelector((state) => state.chatSlice.loginUserId);

  const getRoomsHandler = () => {
    const roomsArray = useMatrixClient.getVisibleRooms();
    dispatch(sortListAfterLastActivity(roomsArray));
    dispatch(getRooms(roomsArray));
  };

  const openChatRoom = (roomId: string) => {
    const room = useMatrixClient.getRoom(roomId);
    dispatch(removeNewMessageNotification(roomId));
    dispatch(getOpenedChatRoomId(roomId));
    dispatch(getRoomInformation(room));
    dispatch(getChatRoomOpenStatus(true));
  };

  const roomFromNetworkCardHandler = (cardId: string) => {
    const roomsArray = useMatrixClient.getVisibleRooms();
    const roomMembers = roomsArray.map(
      (room: { currentState: { members: {} } }) => room.currentState.members
    );
    const reducedRoomMembers = roomMembers.reduce(
      (prev: {}, current: {}) => ({ ...prev, ...current }),
      []
    );
    const roomId =
      reducedRoomMembers[`@${cardId}:${REACT_APP_MATRIX_DOMAIN}`]?.roomId;

    if (roomId) {
      openChatRoom(roomId);
    } else {
      useMatrixClient
        .createRoom({
          invite: [`@${cardId}:${REACT_APP_MATRIX_DOMAIN}`],
          visibility: 'private',
        })
        .then((res: any) => {
          setTimeout(() => openChatRoom(res.room_id), 300);
        })
        .catch((err: any) => {
          console.log('Error creating room:', err);
        });
    }
  };

  useEffect(() => {
    if (useGetNetworkCardId.length > 0) {
      roomFromNetworkCardHandler(useGetNetworkCardId);
      dispatch(getNetworkCardId(''));
    }
  }, [useGetNetworkCardId]);

  useEffect(() => {
    if (useIsChatListUpdateNeeded || isMatrixClientSynced) {
      setIsChatListLoading(true);
      const timeoutHandler = setTimeout(() => {
        setIsChatListLoading(false);
        getRoomsHandler();
      }, 2000);
      return () => clearTimeout(timeoutHandler);
    }
  }, [useIsChatListUpdateNeeded, isMatrixClientSynced]);

  return (
    <div className={styles.chatListWrapper}>
      {useGetChatRoomOpenStatus ? (
        <ChatRoom />
      ) : (
        <>
          {isChatListLoading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.chatListHeader}>{CHAT_ROOMS}</div>
              <div>
                {useGetRooms.length > 0 ? (
                  useGetRooms.map((room) => {
                    const roomMembers: any = room.getMembers();
                    const membersThatAreNotYou = roomMembers.filter(
                      (member: {
                        user: { currentlyActive: boolean };
                        userId: string;
                      }) =>
                        member.userId !==
                        `@${useLoginUserId}:${REACT_APP_MATRIX_DOMAIN}`
                    );
                    return (
                      <motion.div
                        whileHover={{
                          y: '-4px',
                          transition: { duration: 0.4 },
                        }}
                        key={room.roomId}
                        onClick={() => openChatRoom(room.roomId)}
                        className={`${styles.chatListElement} ${
                          useNewMessageNotification.includes(room.roomId)
                            ? styles.newMessageNotification
                            : ''
                        } ${
                          useOnlineUsers.includes(
                            membersThatAreNotYou[0]?.userId
                          )
                            ? styles.online
                            : ''
                        }`}
                      >
                        {room.name.replace(/\(.*\)/g, '')}
                      </motion.div>
                    );
                  })
                ) : (
                  <NoChatList />
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ChatList;
