import styles from './divider.module.scss';

interface Props {
  customStyling?: {
    divider: string;
  };
}

const Divider = ({ customStyling }: Props) => (
  <div
    className={`${styles.divider} ${styles.line} ${styles.oneLine} ${customStyling?.divider}`}
  ></div>
);

export default Divider;
