import { motion, AnimatePresence } from 'framer-motion';
import styles from './noChatList.module.scss';

const NoChatList = () => {
  return (
    <AnimatePresence>
      <motion.div
        key='noChatList'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
        className={styles.noChatListWrapper}
      >
        <div className={styles.noChatListImage}></div>
        <div className={styles.noChatListText}>
          You have no active chats yet. Search for someone to chat with in the
          search input.
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default NoChatList;
