// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REACT_APP_CHAT_SERVICE_URL } from '../../../config';

interface User {
  id: string;
  fullName: string;
}

const usersApiSlice = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_CHAT_SERVICE_URL,
  }),
  endpoints: (builder) => ({
    getUsersByHive: builder.query<
      User[],
      { hiveId: string; usersName: string }
    >({
      query: ({ hiveId, usersName }) =>
        `users/?hiveid=${hiveId}&q=${usersName}`,
    }),
    establishChatConnection: builder.mutation({
      query: ({ userLoginId, hiveId, post }) => ({
        url: `logins/?userId=${userLoginId}&hiveid=${hiveId}`,
        mode: 'cors',
        method: 'POST',
        body: post,
      }),
    }),
    deleteAuthToken: builder.mutation({
      query: ({ authToken }) => ({
        url: 'logins',
        headers: { Authorization: `Bearer ${authToken}` },
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetUsersByHiveQuery,
  useEstablishChatConnectionMutation,
  useDeleteAuthTokenMutation,
} = usersApiSlice;
export default usersApiSlice;
