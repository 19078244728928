import { useEffect, useRef, useState } from 'react';
import { REACT_APP_CDN_URL } from '../../config';
import { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppSelector } from '../../hooks/reduxHooks';
import { v4 as uuidv4 } from 'uuid';
import styles from './exPanelCarousel.module.scss';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

interface Props {
  customStyling?: {
    galleryHeader: string;
    prevSlide: string;
    nextSlide: string;
  };
}

const ExPanelCarousel = ({ customStyling }: Props) => {
  const swiperRef = useRef<any>(null);
  const [carouselItems, setcarouselItems] = useState([]);
  const carouselItemsSelector = useAppSelector(
    (state) => state.xpSlice.carouselItems
  );

  const exPanelGalleryText = useAppSelector(
    (state) => state.xpSlice.galleryText
  );

  useEffect(() => {
    //@ts-ignore
    if (carouselItemsSelector?.length > 0) {
      //@ts-ignore
      setcarouselItems(carouselItemsSelector);
    }
  }, [carouselItemsSelector]);

  return (
    <div className={styles.galleryWrapper}>
      <div
        className={`${styles.galleryHeader} ${customStyling?.galleryHeader}`}
        dangerouslySetInnerHTML={{ __html: exPanelGalleryText }}
      ></div>
      <div
        className={`${styles.arrow} ${styles.prevSlide} ${customStyling?.prevSlide}`}
        data-testid='swiper-prev'
        onClick={() => swiperRef.current.swiper.slidePrev()}
      />

      <div
        className={`${styles.arrow} ${styles.nextSlide} ${customStyling?.nextSlide}`}
        data-testid='swiper-next'
        onClick={() => swiperRef.current.swiper.slideNext()}
      />

      <Swiper
        //@ts-ignore
        ref={swiperRef}
        className={styles.swiper}
        // install Swiper modules
        modules={[Autoplay, Pagination, Navigation]}
        pagination={{
          clickable: true,
          type: 'custom',
        }}
        spaceBetween={50}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        slidesPerView={1}
      >
        {carouselItems?.length > 0 &&
          carouselItems.map((carouselItem: { logo: string; code: string }) => {
            const replaceCDNUrl = carouselItem.logo.replace(
              '{cdn_url}',
              `${REACT_APP_CDN_URL}`
            );
            return (
              <SwiperSlide
                onClick={() => {
                  window.parent.postMessage(
                    { goToScene: carouselItem.code },
                    '*'
                  );
                }}
                className={styles.carouselItem}
                key={uuidv4()}
                style={{
                  backgroundImage: `url(${replaceCDNUrl}`,
                  height: '135px',
                }}
              ></SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default ExPanelCarousel;
