import { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAppSelector } from '../../hooks/reduxHooks';
import styles from './chatMessageSender.module.scss';

interface Props {
  scrollIntoViewHandler: () => void;
}

const ChatMessageSender = ({ scrollIntoViewHandler }: Props) => {
  const [message, setMessage] = useState<string>('');
  const [isMessagingError, setIsMessagingError] = useState<boolean>(false);
  const useRoomId = useAppSelector((state) => state.chatSlice.roomInformation);
  const useMatrixClient = useAppSelector(
    (state) => state.chatSlice.matrixClient
  );

  const sendMessageHandler = () => {
    const content = {
      body: message,
      msgtype: 'm.text',
    };

    useMatrixClient
      .sendEvent(useRoomId?.roomId, 'm.room.message', content, '')

      .then((res: any) => {
        setMessage('');
      })
      .catch((err: any) => {
        setIsMessagingError(true);
        setMessage('');
      });
    setTimeout(scrollIntoViewHandler, 300);
  };

  useEffect(() => {
    if (isMessagingError) {
      setTimeout(() => {
        setIsMessagingError(false);
      }, 3000);
    }
  }, [isMessagingError]);

  const onMessageHandler = (e: { target: { value: string } }) => {
    setMessage(e.target.value);
  };

  return (
    <div className={styles.messageSenderWrapper}>
      <AnimatePresence>
        {isMessagingError && (
          <motion.div
            key='warning'
            className={styles.messageWarning}
            initial={{ y: 0, opacity: 0 }}
            animate={{ y: -20, opacity: 1 }}
            exit={{ opacity: 0, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p>Message was not sent successfully. Try again.</p>
          </motion.div>
        )}
      </AnimatePresence>
      <input
        type='text'
        value={message}
        data-testid='message-input'
        onChange={(e) => onMessageHandler(e)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && message.trim() !== '') {
            sendMessageHandler();
          }
        }}
      />
      <div
        className={styles.sendMessage}
        onClick={() => {
          if (message.trim() !== '') {
            sendMessageHandler();
          }
        }}
        data-testid='send-button'
      ></div>
    </div>
  );
};

export default ChatMessageSender;
