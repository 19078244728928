import { useEffect, useCallback, useState } from 'react';
import '@whereby.com/browser-sdk';
import styles from './videoChatPanel.module.scss';

const VideoChatPanel = () => {
  const [isWhereByActive, setIsWhereByActive] = useState(false);
  const [meetingUrl, setMeetingUrl] = useState('');
  const [userName, setUserName] = useState('');

  const onMessageReceivedFromParent = useCallback(
    (e: {
      data: {
        isWhereByMeetingActive: boolean;
        loginName: string;
        whereByMeetingUrl: string;
      };
    }) => {
      const { isWhereByMeetingActive, loginName, whereByMeetingUrl } = e.data;
      if (isWhereByMeetingActive !== undefined) {
        setIsWhereByActive(isWhereByMeetingActive);
      }
      if (whereByMeetingUrl !== undefined) {
        setMeetingUrl(whereByMeetingUrl);
      }
      if (loginName !== undefined) {
        setUserName(loginName || '');
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromParent);
    return () =>
      window.removeEventListener('message', onMessageReceivedFromParent);
  }, [onMessageReceivedFromParent]);

  useEffect(() => {
    const room = document.querySelector('whereby-embed');

    if (room) {
      const leaveHandler = () => {
        setIsWhereByActive(false);
        window.parent.postMessage({ closeMeeting: true }, '*');
      };

      room.addEventListener('leave', leaveHandler);

      return () => {
        room.removeEventListener('leave', leaveHandler);
      };
    }
  }, [isWhereByActive]);

  return (
    <div className={styles.videoWrapper}>
      {isWhereByActive && (
        //@ts-ignore
        <whereby-embed
          displayName={userName}
          style={{ height: '100%', overflowY: 'hidden' }}
          room={meetingUrl}
        />
      )}
    </div>
  );
};

export default VideoChatPanel;
