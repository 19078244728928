import { configureStore } from '@reduxjs/toolkit';
import chatSlice from './components/chat/slices/chatSlice';
import xpSlice from './components/experiencePanel/slices/xpSlice';
import loungeSlice from './components/lounge/slices/loungeSlice';
import usersApiSlice from './components/chat/slices/usersApiSlice';
import loungeApiSlice from './components/lounge/slices/loungeApiSlice';
import loungeLoginApiSlice from './components/lounge/slices/loungeLoginApiSlice';

const store = configureStore({
  reducer: {
    xpSlice,
    chatSlice,
    loungeSlice,
    // Add the generated reducer as a specific top-level slice
    [usersApiSlice.reducerPath]: usersApiSlice.reducer,
    [loungeApiSlice.reducerPath]: loungeApiSlice.reducer,
    [loungeLoginApiSlice.reducerPath]: loungeLoginApiSlice.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      usersApiSlice.middleware,
      loungeApiSlice.middleware,
      loungeLoginApiSlice.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
