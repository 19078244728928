import { Provider } from 'react-redux';
import store from '../../store';
import Router from './Router';

const AppRoutes = () => (
  <Provider store={store}>
    <Router />
  </Provider>
);

export default AppRoutes;
