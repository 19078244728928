// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { REACT_APP_CHAT_SERVICE_URL } from '../../../config';

const loungeApiSlice = createApi({
  reducerPath: 'loungeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_CHAT_SERVICE_URL,
  }),
  endpoints: (builder) => ({
    getVideoConferences: builder.mutation({
      query: ({ hiveId, loungeSceneId }) => ({
        url: `videoconferences?hiveid=${hiveId}&pageid=${loungeSceneId}&active=true`,
        method: 'GET',
      }),
    }),
    getMeetingParticipants: builder.query({
      query: (meetingId) => `meetings/${meetingId}/participants`,
    }),
    getParticipants: builder.mutation({
      query: ({ loungeSceneId }) => ({
        url: `scenes/${loungeSceneId}/users`,
        method: 'GET',
      }),
    }),
    callParticipant: builder.mutation({
      query: ({ postData, hiveId, loungeSceneId, loginToken }) => ({
        url: `videoconferences?hiveid=${hiveId}&pageid=${loungeSceneId}`,
        method: 'POST',
        body: postData,
        headers: { Authorization: `Bearer ${loginToken}` },
      }),
    }),
    acceptCall: builder.mutation({
      query: ({ putData, videoConferenceId, hiveId }) => ({
        url: `videoconferences/${videoConferenceId}?hiveid=${hiveId}`,
        method: 'PUT',
        body: putData,
      }),
    }),
    addParticipant: builder.mutation({
      query: ({
        postData,
        videoConferenceId,
        hiveId,
        loungeSceneId,
        loginToken,
      }) => ({
        url: `videoconferences/${videoConferenceId}/participants?hiveid=${hiveId}&pageid=${loungeSceneId}`,
        method: 'POST',
        body: postData,
        headers: { Authorization: `Bearer ${loginToken}` },
      }),
    }),
    updateParticipantStatus: builder.mutation({
      query: ({
        putData,
        videoConferenceId,
        userId,
        hiveId,
        loungeSceneId,
      }) => ({
        url: `videoconferences/${videoConferenceId}/participants/${userId}?hiveid=${hiveId}&pageid=${loungeSceneId}`,
        method: 'PUT',
        body: putData,
      }),
    }),
    removeParticipant: builder.mutation({
      query: ({ videoConferenceId, participantId, hiveId, loungeSceneId }) => ({
        url: `videoconferences/${videoConferenceId}/participants/${participantId}?hiveid=${hiveId}&pageid=${loungeSceneId}`,
        method: 'DELETE',
      }),
    }),
    removeVideoConference: builder.mutation({
      query: ({ videoConferenceId, hiveId, userId, loungeSceneId }) => ({
        url: `videoconferences/${videoConferenceId}?hiveid=${hiveId}&userid=${userId}&pageid=${loungeSceneId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useCallParticipantMutation,
  useAcceptCallMutation,
  useGetVideoConferencesMutation,
  useRemoveParticipantMutation,
  useAddParticipantMutation,
  useGetParticipantsMutation,
  useUpdateParticipantStatusMutation,
  useRemoveVideoConferenceMutation,
  useGetMeetingParticipantsQuery,
  useLazyGetMeetingParticipantsQuery,
} = loungeApiSlice;
export default loungeApiSlice;
