import { useEffect, useRef, useState } from 'react';
import { useLazyGetUsersByHiveQuery } from './slices/usersApiSlice';
import { useAppSelector } from '../../hooks/reduxHooks';
import { motion, AnimatePresence } from 'framer-motion';
import ChatSearchContent from './ChatSearchContent';
import useDebounce from '../../hooks/useDebounce';
import styles from './chatSearchBar.module.scss';

const ChatSearchBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchedRooms, setSearchedRooms] = useState<any>([]);
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [trigger, { data, error, isLoading }] = useLazyGetUsersByHiveQuery();
  const useGetRooms = useAppSelector((state) => state.chatSlice.rooms);
  const useHiveId = useAppSelector((state) => state.chatSlice.hiveId);
  const { LATIN_REQUIREMENT, CHAT_SEARCH_FIRSTNAME } = useAppSelector(
    (state) => state.chatSlice.translations
  );

  const handleSearchFocus = () => searchRef.current?.focus();

  const onSearchHandler = (e: { target: { value: string } }) => {
    //make sure first line is not space
    if (e.target.value.charCodeAt(0) !== 32) {
      setSearchTerm(e.target.value);
    }
  };
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsOpen(true);
      trigger(
        {
          hiveId: useHiveId,
          usersName: searchTerm,
        },
        true
      );

      const filteredChatRooms = useGetRooms.filter((room) =>
        room.name.match(new RegExp(searchTerm, 'i'))
      );
      setSearchedRooms(filteredChatRooms);
    } else {
      setIsOpen(false);
    }
  }, [debouncedSearchTerm]);

  return (
    <div className={styles.searchContentWrapper}>
      <div className={styles.searchBarWrapper}>
        <div onClick={handleSearchFocus} className={styles.searchIconWrapper}>
          <div data-testid='search-icon' className={styles.searchIcon}></div>
        </div>
        <input
          ref={searchRef}
          placeholder={CHAT_SEARCH_FIRSTNAME}
          onChange={(e) => onSearchHandler(e)}
          className={styles.searchBar}
          type='search'
          name='searchName'
        ></input>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.section
            className={styles.usersWrapper}
            key='content'
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: {
                opacity: 1,
                height: '100vh',
              },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 1,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            {error ? (
              <>{LATIN_REQUIREMENT}</>
            ) : isLoading ? (
              <></>
            ) : data ? (
              <ChatSearchContent
                data={data}
                searchedRooms={searchedRooms}
                setIsOpen={setIsOpen}
                searchRef={searchRef}
              />
            ) : null}
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ChatSearchBar;
