import { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import {
  setWhereByMeetingUrl,
  setWhereByOpenStatus,
  incomingVideoCallInfo,
} from "../slices/loungeSlice";
import styles from "./meetingCard.module.scss";
import { useGetMeetingParticipantsQuery } from "../slices/loungeApiSlice";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import React from "react";

interface Props {
  title: string;
  description: string;
  meetingUrl: string;
  id: string;
  imageIndex: number;
  participantCount?: any;
  onIdUpdate: (id: string) => void;
}

export default function MeetingCard({
  id,
  title,
  description,
  meetingUrl,
  imageIndex,
  onIdUpdate,
}: Props) {
  const [isHovered, setIsHovered] = useState(false);

  const [participantCount, setParticipantCount] = useState(0);

  const dispatch = useAppDispatch();
  const roomId = useAppSelector((state) => state.loungeSlice.loungeSceneId);

  const {
    data: participants,
    isLoading,
    isError,
  } = useGetMeetingParticipantsQuery(id);

  const handleMeetingCardData = () => {
    onIdUpdate(id);
  };

  const attendLoungeMeetingHandler = () => {
    dispatch(setWhereByMeetingUrl(meetingUrl));
    dispatch(setWhereByOpenStatus(true));
    dispatch(incomingVideoCallInfo(""));
    handleMeetingCardData();
  };

  const messageHandler = useCallback(
    (event: any) => {
      const { action, identifier } = event.data;
      if (identifier === id) {
        if (action === "meetingEntered") {
          setParticipantCount((prev) => prev + 1);
        } else if (action === "meetingLeft") {
          setParticipantCount((prev) => prev - 1);
        }
      }
    },
    [id]
  );

  useEffect(() => {
    const handleMessage = messageHandler;

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  });

  useEffect(() => {
    setParticipantCount(participants?.length);
  }, [participants]);

  return (
    <>
      <Tooltip
        title={
          <React.Fragment>
            <Typography variant="body1" style={{ textTransform: "uppercase" }}>
              {title}
              <br />
            </Typography>
            <Typography variant="body1">{description}</Typography>
          </React.Fragment>
        }
        placement="left"
        arrow
      >
        <div
          className={styles.meetingCard}
          onClick={attendLoungeMeetingHandler}
          data-testid="meeting-card"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className={styles.imageContainer}>
            <img
              className={styles.image}
              src={`/assets/meetingStockPhotos/forest-${imageIndex}.jpg`}
              alt="forest"
            />
          </div>

          <div className={styles.meetingCardInfoWrapper}>
            <div className={styles.name}>{title}</div>
            <div className={styles.meetingCardInfoHandlerWrapper}>
              <motion.div className={styles.meetingInformationContainer}>
                <div className={styles.participantHover}>
                  {isHovered ? (
                    "Click to enter room"
                  ) : (
                    <div className={styles.participantCount}>
                      <div>
                        {isLoading
                          ? "Loading..."
                          : isError
                          ? "Error loading participants"
                          : participantCount <= 0
                          ? "Room is empty"
                          : `Participants in room: ${participantCount}`}
                      </div>
                    </div>
                  )}
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </Tooltip>
    </>
  );
}
