import { motion } from 'framer-motion';
import { getIsCallWaitingStatus } from '../slices/loungeSlice';
import { useAppSelector, useAppDispatch } from '../../../hooks/reduxHooks';
import styles from './loungeWaitingCard.module.scss';
import { useRemoveVideoConferenceMutation } from '../slices/loungeApiSlice';

const LoungeWaitingCard = () => {
  const dispatch = useAppDispatch();
  const useParticipantName = useAppSelector(
    (state) => state.loungeSlice.participantName
  );

  const useWhereByMeetingUrl = useAppSelector(
    (state) => state.loungeSlice.whereByMeetingUrl
  );

  const useHiveId = useAppSelector(
    (state) => state.loungeSlice.loginUserHiveId
  );

  const useUserInformation = useAppSelector(
    (state) => state.loungeSlice.userInformation
  );

  const useLoungeSceneId = useAppSelector(
    (state) => state.loungeSlice.loungeSceneId
  );

  const { VIDEO_CHAT_REGRET_CALL } = useAppSelector(
    (state) => state.loungeSlice.translations
  );

  const [removeVideoConferenceTrigger] = useRemoveVideoConferenceMutation();

  const wrapperVariants = {
    hidden: { scale: 0.3, opacity: 0 },
    visible: { scale: 1, opacity: 1 },
  };

  const regretCallHandler = () => {
    removeVideoConferenceTrigger({
      videoConferenceId: useWhereByMeetingUrl,
      hiveId: useHiveId,
      userId: useUserInformation.loginUserId,
      loungeSceneId: useLoungeSceneId,
    });
    dispatch(getIsCallWaitingStatus(false));
  };

  return (
    <motion.div
      variants={wrapperVariants}
      initial='hidden'
      animate='visible'
      transition={{ duration: 0.5 }}
      exit={{
        scale: 0.3,
        opacity: 0,
        transition: {
          delay: 0.3,
          duration: 0.5,
        },
      }}
      className={styles.waitingWrapper}
    >
      <div className={styles.participantBackground}></div>
      <motion.div
        variants={wrapperVariants}
        transition={{ delay: 0.3, duration: 0.5 }}
        exit={{ opacity: 0, scale: 0.3, transition: { duration: 0.5 } }}
        className={styles.callIconWrapper}
      >
        <div className={styles.callIcon}></div>
      </motion.div>
      <div className={styles.participantNameWrapper}>
        <div className={styles.calling}>Calling</div>
        <div className={styles.participantName}>{useParticipantName}</div>
      </div>
      <motion.div
        whileHover={{ scale: 1.04 }}
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.4 }}
        className={styles.declineCall}
        onClick={regretCallHandler}
      >
        {VIDEO_CHAT_REGRET_CALL}
      </motion.div>
    </motion.div>
  );
};

export default LoungeWaitingCard;
