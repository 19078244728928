import { motion } from 'framer-motion';
import { useAppSelector } from '../../hooks/reduxHooks';
import ExPanelProgramList from './ExPanelProgramList';
import styles from './exPanelProgramWrapper.module.scss';
import { useState } from 'react';

interface Props {
  customStyling?: {
    programHeader: string;
    program: string;
    noContentTitle: string;
    programWrapper: string;
    programWrapperFullProgram: string;
    customText: string;
    filterBtn: string;
    activeBtn: string;
  };
}

const ExPanelProgramWrapper = ({ customStyling }: Props) => {
  const exPanelProgramText = useAppSelector(
    (state) => state.xpSlice.programText
  );

  const [isLive, setIsLive] = useState(false);

  const { PROGRAM, PROGRAM_ALL_PROGRAMS } = useAppSelector(
    (state) => state.xpSlice.translations
  );

  return (
    <>
      {exPanelProgramText.text?.length > 0 && exPanelProgramText.allowed ? (
        <div
          className={`${styles.customText} ${customStyling?.customText}`}
          dangerouslySetInnerHTML={{ __html: exPanelProgramText.text }}
        ></div>
      ) : (
        <>
          <div
            className={`${styles.programHeader} ${customStyling?.programHeader}`}
          >
            {PROGRAM}
          </div>
          <div className={`${styles.filterBtn} ${customStyling?.filterBtn}`}>
            <motion.button
              whileHover={{ scale: 1.06 }}
              whileTap={{ scale: 1 }}
              animate={{ opacity: !isLive ? 1 : 0.6 }}
              onClick={() => setIsLive(false)}
            >
              {PROGRAM_ALL_PROGRAMS !== undefined
                ? PROGRAM_ALL_PROGRAMS
                : 'All'}
            </motion.button>
            <motion.button
              animate={{ opacity: isLive ? 1 : 0.6 }}
              whileHover={{ scale: 1.06 }}
              whileTap={{ scale: 1 }}
              onClick={() => setIsLive(true)}
            >
              Live
            </motion.button>
          </div>
          <div
            className={`${styles.programWrapper} ${customStyling?.programWrapper}`}
          >
            <ExPanelProgramList customStyling={customStyling} isLive={isLive} />
          </div>
        </>
      )}
    </>
  );
};

export default ExPanelProgramWrapper;
