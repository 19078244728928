interface Props {
  userName: string;
  className: string;
}

const ProfileImagePlaceholder = ({ userName, className }: Props) => {
  if (userName?.length > 0) {
    const initials = userName

      .split(' ')
      .map((name: string) => name.charAt(0))
      .join('');

    return (
      <div data-testid='initials' className={className}>
        {initials}
      </div>
    );
  }

  return null;
};

export default ProfileImagePlaceholder;
