import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import { AnimatePresence, motion } from "framer-motion";
import LoungeParticipantCard from "../loungeParticipantCard/LoungeParticipantCard";
import styles from "./loungeParticipants.module.scss";
import { useGetParticipantsMutation } from "../slices/loungeApiSlice";
import {
  addLoungeParticipant,
  removeAllLoungeParticipants,
} from "../slices/loungeSlice";
import useDebounce from "../../../hooks/useDebounce";

const LoungeParticipants = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [listOfParticipants, setListOfParticipants] = useState<
    { userId: string; userName: string }[]
  >([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [getParticipantsTrigger, { data: getParticipantsData }] =
    useGetParticipantsMutation();
  const useLoungeParticipants = useAppSelector(
    (state) => state.loungeSlice.loungeParticipants
  );

  const { NETWORKING_PARTICIPANTS } = useAppSelector(
    (state) => state.loungeSlice.translations
  );

  const useUserInformation = useAppSelector(
    (state) => state.loungeSlice.userInformation
  );

  const useLoungeSceneId = useAppSelector(
    (state) => state.loungeSlice.loungeSceneId
  );

  const { VIDEO_CHAT_CLEAR, VIDEO_CHAT_SEARCH_FOR_PARTICIPANT } =
    useAppSelector((state) => state.loungeSlice.translations);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (useLoungeParticipants.length > 0) {
      const updatedGroups = getParticipantGroups(useLoungeParticipants);
      setListOfParticipants(updatedGroups.flat());
    } else {
      setListOfParticipants([]);
    }
  }, [useLoungeParticipants]);

  useEffect(() => {
    if (useLoungeSceneId?.length > 0) {
      getParticipantsTrigger({ loungeSceneId: useLoungeSceneId });
    }
  }, [useLoungeSceneId, getParticipantsTrigger]);

  useEffect(() => {
    if (getParticipantsData?.length > 0 && useLoungeSceneId?.length > 0) {
      dispatch(addLoungeParticipant(getParticipantsData));
    } else {
      dispatch(removeAllLoungeParticipants());
    }
  }, [getParticipantsData, dispatch, useLoungeSceneId]);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (
        event.key === "Escape" &&
        document.activeElement === searchInputRef.current
      ) {
        setSearchTerm("");
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const getParticipantGroups = (participants: any) => {
    const groups = [];
    let currentGroup: any[] = [];

    participants.forEach((participant: any) => {
      // 15 is max per column
      if (currentGroup.length < 15) {
        currentGroup.push(participant);
      } else {
        groups.push(currentGroup);
        currentGroup = [participant];
      }
    });

    // Push the last group if it has participants
    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    return groups;
  };

  const participantGroups = getParticipantGroups(listOfParticipants);

  const filteredParticipants = participantGroups.map((group) =>
    group.filter((participant: { userName: string }) =>
      participant.userName
        .toLowerCase()
        .includes(debouncedSearchTerm.toLowerCase())
    )
  );

  return (
    <div className={styles.container}>
      {/* Top bar and search bar (rendered once) */}
      <div className={styles.participantsTopBar}>
        <div>{`${NETWORKING_PARTICIPANTS} (${listOfParticipants.length})`}</div>
      </div>
      <motion.div className={styles.searchWrapper}>
        <input
          className={styles.searchBar}
          placeholder={VIDEO_CHAT_SEARCH_FOR_PARTICIPANT}
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          ref={searchInputRef}
        />
        {searchTerm && (
          <motion.button
            className={styles.clearSearchButton}
            onClick={() => setSearchTerm("")}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            whileHover={{ scale: 1.04 }}
            whileTap={{ scale: 0.9 }}
          >
            {VIDEO_CHAT_CLEAR}
          </motion.button>
        )}
      </motion.div>

      {/* Participant groups */}
      {filteredParticipants.map((group, index) => (
        <div key={index} className={styles.participantsWrapper}>
          {/* List of Participants */}
          <AnimatePresence>
            <div className={styles.participantsList}>
              {group.map((participant) => (
                <LoungeParticipantCard
                  key={participant.userId}
                  participant={participant}
                  you={participant.userId === useUserInformation.loginUserId}
                />
              ))}
            </div>
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default LoungeParticipants;
