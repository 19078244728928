import { motion } from 'framer-motion';
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import {
  getRoomInformation,
  getChatRoomOpenStatus,
  isChatListUpdateNeeded,
  getOpenedChatRoomId,
} from './slices/chatSlice';
import { REACT_APP_MATRIX_DOMAIN } from '../../config';
import styles from './chatSearchBar.module.scss';

interface Props {
  data: {
    id: string;
    fullName: string;
  }[];

  searchedRooms?: any;
  setIsOpen: (value: boolean) => void;
  searchRef: { current: { value: string } | null };
}
const ChatSearchContent = ({
  data,
  searchedRooms,
  setIsOpen,
  searchRef,
}: Props) => {
  const dispatch = useAppDispatch();

  const useMatrixClient = useAppSelector(
    (state) => state.chatSlice.matrixClient
  );
  const { CHAT_CREATE_NEW, CHAT_JOIN_EXISTING, COULD_NOT_FIND_PERSON } =
    useAppSelector((state) => state.chatSlice.translations);

  const createRoomHandler = (userId: string) => {
    dispatch(isChatListUpdateNeeded(false));
    useMatrixClient
      .createRoom({
        invite: [`@${userId}:${REACT_APP_MATRIX_DOMAIN}`],
        visibility: 'private',
      })
      .then((res: any) => {
        dispatch(isChatListUpdateNeeded(true));
        setIsOpen(false);
        setTimeout(() => openChatRoom(res.room_id), 300);
        if (searchRef.current !== null) {
          searchRef.current.value = '';
        }
      })
      .catch((err: any) => {
        console.log('>>', err);
      });
  };

  const openChatRoom = (roomId: string) => {
    const room = useMatrixClient.getRoom(roomId);
    dispatch(getRoomInformation(room));
    dispatch(getOpenedChatRoomId(roomId));
    dispatch(getChatRoomOpenStatus(true));
    setIsOpen(false);
    if (searchRef.current !== null) {
      searchRef.current.value = '';
    }
  };
  return (
    <>
      {data.length > 0 || searchedRooms.length > 0 ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {data.length > 0 && (
            <div className={styles.searchContentTitle}>{CHAT_CREATE_NEW}</div>
          )}
          {data.map((user: { fullName: string; id: string }) => {
            return (
              <div
                className={styles.user}
                key={user.id}
                onClick={() => {
                  createRoomHandler(user.id);
                }}
              >
                {user.fullName}
              </div>
            );
          })}
          <div>
            {searchedRooms.length > 0 && (
              <div className={styles.searchContentTitle}>
                {CHAT_JOIN_EXISTING}
              </div>
            )}
            {searchedRooms.map(
              (searchedRoom: { roomId: string; name: string }) => (
                <div
                  className={styles.userChat}
                  key={searchedRoom.roomId}
                  onClick={() => openChatRoom(searchedRoom.roomId)}
                >
                  {searchedRoom.name}
                </div>
              )
            )}
          </div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={styles.noResultWrapper}
        >
          <div className={styles.noResult}></div>
          <div className={styles.noResultInfo}>{COULD_NOT_FIND_PERSON}</div>
        </motion.div>
      )}
    </>
  );
};

export default ChatSearchContent;
