import { useEffect, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import {
  getProgram,
  getCarouselItems,
  getTeaser,
  getSoMeIcons,
  getSoMeLinks,
  getProgramText,
  getGalleryText,
  getHiveName,
  getXpTranslations,
  getHiveId,
  getLoginUserId,
} from './slices/xpSlice';
import ExPanelProgramWrapper from './ExPanelProgramWrapper';
import ExPanelCarousel from './ExPanelCarousel';
import ExPanelTeaser from './ExPanelTeaser';
import ExPanelSoMe from './ExPanelSoMe';
import Divider from '../divider/Divider';
import styles from './expanel.module.scss';
interface ClientData {
  hiveName: string;
}

interface StylingClasses {
  sideWrapper: string;
  programHeader: string;
  program: string;
  galleryHeader: string;
  prevSlide: string;
  nextSlide: string;
  noContentTitle: string;
  programWrapper: string;
  programWrapperFullProgram: string;
  divider: string;
  customText: string;
  iconContainer: string;
  filterBtn: string;
  activeBtn: string;
  scoreboardHeader: string;
}

const ExPanelSidebar = () => {
  const dispatch = useAppDispatch();
  const [clientData, setClientData] = useState<ClientData>({ hiveName: '' });
  const [customStyling, setCustomStyling] = useState<StylingClasses>({
    sideWrapper: '',
    programHeader: '',
    program: '',
    galleryHeader: '',
    prevSlide: '',
    nextSlide: '',
    noContentTitle: '',
    programWrapper: '',
    programWrapperFullProgram: '',
    divider: '',
    customText: '',
    iconContainer: '',
    filterBtn: '',
    activeBtn: '',
    scoreboardHeader: '',
  });

  const onMessageReceivedFromParent = useCallback((e: any) => {
    setClientData({ ...clientData, ...e.data });
    if (e.data.xpTranslations) {
      dispatch(getXpTranslations(e.data));
    }
    dispatch(getProgram(e.data));
    dispatch(getCarouselItems(e.data));
    dispatch(getTeaser(e.data));
    if (e.data.soMeIcons !== undefined && e.data.soMeLinks !== undefined) {
      dispatch(getSoMeIcons(e.data));
      dispatch(getSoMeLinks(e.data));
    }
    dispatch(getProgramText(e.data));
    if (e.data.galleryText !== undefined) {
      dispatch(getGalleryText(e.data));
    }
    dispatch(getHiveName(e.data));
    dispatch(getHiveId(e.data));
    dispatch(getLoginUserId(e.data));
  }, []);

  //custom styling import
  useEffect(() => {
    if (clientData.hiveName.length > 0) {
      import(
        /* webpackMode: "eager" */
        `../../customStyling/${clientData.hiveName}.module.scss`
      ).then((imported) => {
        setCustomStyling(imported.default);
      });
    }
  }, [clientData.hiveName]);

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromParent);
    return () =>
      window.removeEventListener('message', onMessageReceivedFromParent);
  }, [onMessageReceivedFromParent]);

  const soMeIconsSelector = useAppSelector((state) => state.xpSlice.soMeIcons);

  return (
    <div
      data-testid='sidebar'
      className={`${styles.sideWrapper} ${customStyling.sideWrapper}`}
    >
      <div className={styles.contentWrapper}>
        <ExPanelTeaser />
        <Divider customStyling={customStyling} />
        <ExPanelProgramWrapper customStyling={customStyling} />
        <Divider customStyling={customStyling} />
        <ExPanelCarousel customStyling={customStyling} />
        {soMeIconsSelector?.length > 0 && (
          <>
            <Divider customStyling={customStyling} />
            <ExPanelSoMe customStyling={customStyling} />
          </>
        )}
      </div>
    </div>
  );
};

export default ExPanelSidebar;
