import { useEffect } from "react";
import styles from "./loungeMeetings.module.scss";
import { useLazyGetLoungeMeetingsQuery } from "../slices/loungeLoginApiSlice";
import { useAppSelector } from "../../../hooks/reduxHooks";
import MeetingCard from "../meetingCard/MeetingCard";

interface Meeting {
  id: string;
  title: string;
  description: string;
  joinUrl: string;
}

interface LoungeMeetingsProps {
  onMeetingCardIdUpdate: (id: string) => void;
}

export default function LoungeMeetings({
  onMeetingCardIdUpdate,
}: LoungeMeetingsProps) {
  const [
    getLoungeMeetingsTrigger,
    { data: loungeMeetingsData, isSuccess: isGetLoungeMeetingsFetched },
  ] = useLazyGetLoungeMeetingsQuery();

  const useLoginToken = useAppSelector((state) => state.loungeSlice.loginToken);
  const useHiveId = useAppSelector(
    (state) => state.loungeSlice.loginUserHiveId
  );
  const { NETWORKING_MEETINGS } = useAppSelector(
    (state) => state.loungeSlice.translations
  );
  const useLoungeSceneId = useAppSelector(
    (state) => state.loungeSlice.loungeSceneId
  );
  const useUserInformation = useAppSelector(
    (state) => state.loungeSlice.userInformation.loginUserId
  );

  useEffect(() => {
    if (useHiveId && useUserInformation && useLoungeSceneId.length > 0) {
      getLoungeMeetingsTrigger({
        hiveId: useHiveId,
        loginId: useUserInformation,
        loginToken: useLoginToken,
        loungeSceneId: useLoungeSceneId,
      });
    }
  }, [
    useHiveId,
    useLoginToken,
    useUserInformation,
    useLoungeSceneId,
    getLoungeMeetingsTrigger,
  ]);

  return (
    <div className={styles.loungeMeetingsWrapper}>
      <div className={styles.meetingsTopBar}>
        <div>{`${NETWORKING_MEETINGS} (${
          loungeMeetingsData ? loungeMeetingsData.length : 0
        })`}</div>
      </div>
      <div className={styles.loungeMeetingsList}>
        {isGetLoungeMeetingsFetched &&
          loungeMeetingsData.map((meeting: Meeting, index: number) => (
            <MeetingCard
              key={meeting.id}
              id={meeting.id}
              imageIndex={index}
              title={meeting.title}
              description={meeting.description}
              meetingUrl={meeting.joinUrl}
              onIdUpdate={onMeetingCardIdUpdate}
            />
          ))}
      </div>
    </div>
  );
}
