import { Route, Routes } from 'react-router-dom';
import { routerType } from './router.types';
import routesData from './routesData';

const Router = () => {
  const pageRoutes = routesData.map(({ path, key, element }: routerType) => {
    return <Route key={key} path={`/${path}`} element={element} />;
  });

  return <Routes>{pageRoutes}</Routes>;
};

export default Router;
